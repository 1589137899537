<template>
  <div class="page-container">
    <DataBox style="margin-top: 12px" :data="pageData" />

    <template v-if="showServiceFee">
      <div class="sub-title">服务费明细</div>
      <div class="list">
        <div v-if="pageData.actualEligibleActiveDays === 1" class="list-item">
          <div class="label">活跃合格次数</div>
          <div class="content number">
            {{ pageData.actualEligibleActiveDaysNum != null ? pageData.actualEligibleActiveDaysNum : '--' }}
          </div>
        </div>
        <div v-if="pageData.basicTaskServiceFee === 1" class="list-item">
          <div class="label">基础任务服务费</div>
          <div class="content number">
            {{ pageData.basicTaskServiceFeeNum != null ? pageData.basicTaskServiceFeeNum : '--' }}
          </div>
        </div>
        <div v-if="pageData.basicTaskServiceFee === 1 && pageData.projectCategory !== 'DP'" class="list-item">
          <div class="label">临时任务服务费</div>
          <div class="content number">
            {{ pageData.temporaryTaskServiceFeeNum != null ? pageData.temporaryTaskServiceFeeNum : '--' }}
          </div>
        </div>

        <div v-if="pageData.salesBonus === 1" class="list-item">
          <div class="label">销售奖金</div>
          <div class="content number">
            {{ pageData.salesBonusNum != null ? pageData.salesBonusNum : '--' }}
          </div>
        </div>
        <div v-if="pageData.otherExpenses === 1" class="list-item">
          <div class="label">其他费用</div>
          <div class="content number">
            {{ pageData.otherExpensesNum != null ? pageData.otherExpensesNum : '--' }}
          </div>
        </div>
        <!-- <div class="list-item">
          <div class="label">总计</div>
          <div class="content number">
            {{ pageData.totalServiceFee }}
          </div>
        </div> -->
        <div v-if="pageData.remark === 1" class="list-item">
          <div class="label">备注</div>
          <div class="content">
            {{ pageData.remarkNum != null ? pageData.remarkNum : '--' }}
          </div>
        </div>
      </div>
    </template>
    <div v-if="pageData.subscribeTenants === 0" style="text-align: center; font-size: 14px; color: #999">
      该结算单仅查看参考，请以实际发放为准
    </div>
  </div>
</template>

<script>
import DataBox from './components/DataBox.vue'
import { taskSettlementRead } from '@/api/serviceCharge'

export default {
  components: {
    DataBox
  },
  data() {
    return {
      pageData: {}
    }
  },
  methods: {
    getData() {
      const serviceChargeDetail = sessionStorage.getItem('serviceChargeDetail')
      console.log('serviceChargeDetail: ', serviceChargeDetail)
      this.pageData = JSON.parse(serviceChargeDetail || '{}')
      taskSettlementRead({ id: this.$route.query.id, empPk: this.$route.query.empPk })
    }
  },
  mounted() {
    this.getData()
  },

  computed: {
    showServiceFee() {
      const { actualEligibleActiveDays, basicTaskServiceFee, salesBonus, otherExpenses, remark } = this.pageData
      return actualEligibleActiveDays || basicTaskServiceFee || salesBonus || otherExpenses || remark
    }
  }
}
</script>

<style lang="less" scoped>
.sub-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-top: 24px;
  margin-left: 8px;
}
.list {
  margin: 12px 8px;
  padding: 4px 20px;
  background-color: #fff;
  border-radius: 8px;
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #ededed;
    &:last-child {
      border-bottom: none;
    }
    .label {
      font-size: 16px;
      color: #333;
    }
    .content {
      font-size: 16px;
      color: #666;
      margin-left: 12px;
      &.number {
        color: #21aeff;
      }
    }
  }
}
</style>
